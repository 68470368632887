import React from 'react';
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from 'gatsby';
import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";
import colors from "styles/colors";
import Layout from "components/Layout";

const PageHeroContainer = styled("div")`
    max-height: 500px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 3em;

    img {
        width: 100%;
    }
`

const PageHeroAnnotation = styled("div")`
    padding-top: 0.25em;

    h6 {
        text-align: right;
        color: ${colors.grey600};
        font-weight: 400;
        font-size: 0.85rem;
    }

    a {
        color: currentColor;
    }
`

const PageTitle = styled("div")`
    max-width: 550px;
    margin: 0 auto;
    text-align: center;

    h1 {
        margin-top: 0;
    }
`

const PageBody = styled("div")`
    max-width: 800px;
    margin: 0 auto;

    .block-img {
        margin-top: 3.5em;
        margin-bottom: 0.5em;

        img {
            width: 100%;
        }
    }
`

const Page = ({ page, meta }) => {
    return (
        <>
            <Helmet
                title={`${page.page_title[0].text} | Antanavo bendruomenė`}
                titleTemplate={`%s | ${meta.title}`}
                meta={[
                    {
                        name: `description`,
                        content: meta.description,
                    },
                    {
                        property: `og:title`,
                        content: `${page.page_title[0].text} | Antanavo bendruomenė`,
                    },
                    {
                        property: `og:description`,
                        content: meta.description,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `og:image`,
                        content: page.page_hero_image.url,
                    },
                    {
                        property: `fb:app_id`,
                        content: meta.fbappid,
                    },
                    {
                        property: `og:url`,
                        content: meta.siteurl + `/` + page._meta.uid,
                    },
                    {
                        name: `twitter:card`,
                        content: `summary_large_image`,
                    },
                    {
                        name: `twitter:creator`,
                        content: meta.author,
                    },
                    {
                        name: `twitter:title`,
                        content: meta.title,
                    },
                    {
                        name: `twitter:description`,
                        content: meta.description,
                    },
                ].concat(meta)}   
            >
                <html lang="lt" />
            </Helmet>
            <Layout>
                <PageTitle>
                    {RichText.render(page.page_title)}
                </PageTitle>
                    {page.page_hero_image && (
                    <PageHeroContainer>
                        <img src={page.page_hero_image.url} alt={page.page_hero_image.alt} />
                        <PageHeroAnnotation>
                            {RichText.render(page.page_hero_annotation)}
                        </PageHeroAnnotation>
                    </PageHeroContainer>
                )}
                <PageBody>
                    {RichText.render(page.page_body)}
                </PageBody>
            </Layout>
        </>
    )
}

export default ({ data }) => {
    const pageContent = data.prismic.allPages.edges[0].node;
    const meta = data.site.siteMetadata;
    return (
        <Page page={pageContent} meta={meta}/>
    )
}

Page.propTypes = {
    page: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
};

export const query = graphql`
    query PageQuery($uid: String) {
        prismic {
            allPages(uid: $uid) {
                edges {
                    node {
                        page_title
                        page_hero_image
                        page_hero_annotation
                        page_body
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
                fbappid
                siteurl
            }
        }
    }
`